<template>
  <div class="wrapper">
    <el-input v-model="inputVal" :disabled="disabledFn" placeholder="请选择对应员工">
      <template slot="append">
        <el-link type="primary" @click="openDialog" class="vg_cursor" :disabled="disabled">选择</el-link>
      </template>
    </el-input>
    <el-dialog :title="title" :visible.sync="dialogTableVisible" @close="closeDialog">
      <template>
        <el-button type="danger" @click="cancelSelection" v-if="isShowCol">取消选择</el-button>
        <el-button type="primary" @click="sureSelection" v-if="isShowCol">确认选择</el-button>
        <span class="vd_tips" v-if="isShowCol === false">提示：(双击表单对应数据即可选中人员)</span>
        <div style="float:right;margin-bottom:10px;display:flex;">
          <el-input
            v-model="searchContent"
            placeholder="请输入匹配关键字"
            size="medium"
          >
          </el-input>
          <el-button  style="margin-left:10px;" type="info" @click="refreshTableData">刷新</el-button>
        </div>
      </template>
      <div v-if="flags">
        <el-table
          ref="multipleTable"
          :data="filterData"
          border
          size="mini"
          max-height="600"
          fit highlight-current-row
          @row-dblclick="rowDblclick"
          v-loading="loading"
          @selection-change="handleSelectionChange"
          @select-all="selectAll"
          v-if="flag === 0"
          class="vg_pointer"
          
        >
<!--        :rowSelection="{selectedRowKeys: selectedRowKeys, onChange: onChange}"-->
<!--        :reserve-selection="newSure"-->
          <el-table-column type="selection" width="55" align="center"  v-if="isShowCol === true"></el-table-column>
          <!-- :selectable="selecExternal"@select="handleSelection" -->
          <el-table-column type="index" align="center" width="55" fixed></el-table-column>
          <el-table-column v-for="(th, key) in tableHeader"
                          :key="key"
                          :prop="th.prop"
                          :label="th.label"
                          :fixed="th.fixed"
                          :min-width="th.minWidth" align="center">
          </el-table-column>
        </el-table>
        <el-table
            ref="multipleTable1"
            :data="filterData"
            border
            size="mini"
            max-height="600"
            fit highlight-current-row
            @row-dblclick="rowDblclick"
            v-loading="loading"
            @selection-change="handleSelectionChange1"
            v-else-if="flag === 1"
            
        >
  <!--        :rowSelection="{selectedRowKeys: selectedRowKeys, onChange: onChange}"-->
  <!--        :reserve-selection="newSure"-->
          <el-table-column type="selection" :selectable="selecExternal" width="55" align="center"  v-if="isShowCol === true"></el-table-column>
          <!-- @select="handleSelection" -->
          <el-table-column type="index" align="center" width="55" fixed></el-table-column>
          <el-table-column v-for="(th, key) in tableHeader"
                          :key="key"
                          :prop="th.prop"
                          :label="th.label"
                          :fixed="th.fixed"
                          :min-width="th.minWidth" align="center">
          </el-table-column>
        </el-table>
        <el-table
            ref="multipleTable2"
            :data="filterData"
            border
            size="mini"
            max-height="600"
            fit highlight-current-row
            @row-dblclick="rowDblclick"
            v-loading="loading"
            @selection-change="handleSelectionChange2"
            v-else-if="flag === 2"
            
        >
  <!--        :rowSelection="{selectedRowKeys: selectedRowKeys, onChange: onChange}"-->
  <!--        :reserve-selection="newSure"-->
          <el-table-column type="selection" :selectable="selecExternal1" width="55" align="center"  v-if="isShowCol === true"></el-table-column>
          <!-- :selectable="selecExternal"@select="handleSelection" -->
          <el-table-column type="index" align="center" width="55" fixed></el-table-column>
          <el-table-column v-for="(th, key) in tableHeader"
                          :key="key"
                          :prop="th.prop"
                          :label="th.label"
                          :fixed="th.fixed"
                          :min-width="th.minWidth" align="center">
          </el-table-column>
        </el-table>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "selectInput",
  data(){
    return{
      title:'',
      dialogTableVisible:false,
      searchContent:'',
      disabledFn: true,
      newRow:{},
      multiSelection:[],
      newSure: true,
      selectedRowKeys: [],
      selectedRows: [],
      flag:0,
      flags:true,
      whether:false
    }
  },
  props:{
    selTableData:{
      type: Array,
      default: function () {
        return []
      }
    },
    isShowCol:{
      type:Boolean,
      default: function (){
        return Boolean
      }
    },
    // rowDblclick:{
    //   type: Function,
    //   default:(row,event, column) => { console.log('default: ' + row + '---' + event + '---' + column) }
    // },
    disabled:{
      type:Boolean,
      default: function (){
        return {}
      }
    },
    tableHeader: {
      type: Array,
      default: function (){
        return []
      }
    },
    inputVal: {
      type:String,
      default: function (){
        return {}
      }
    },
    rowSelect: {
      type:Array,
      default: function (){
        return{}
      }
    },
    loading:{
      type: Boolean,
      default: false
    }
  },
  computed:{
    filterData: function (){
      let input = this.searchContent
      let items = this.selTableData
      let items1
      if (input) {
        items1 = items.filter(function(item) {
          return Object.keys(item).some(function(key1) {
            return String(item[key1]).match(input);
          });
        });
      } else {
        items1 = items;
      }
      return items1;
    }
  },
  methods:{
    toggleSelection(rows){
      if (rows) {
        rows.forEach(row => {
          if(this.flag === 1){
            this.$refs.multipleTable1.toggleRowSelection(row, true)
          }else if(this.flag === 2){
            this.$refs.multipleTable2.toggleRowSelection(row, true)
          }else {
            this.$refs.multipleTable.toggleRowSelection(row, true)
          }
        }) 
      } else {
        this.$refs.multipleTable.clearSelection()
      }
    },
    selectAll(){
      this.$refs.multipleTable.clearSelection()
    },
    openDialog(){
      if(this.rowSelect.length === 0){
        this.flags = false;
        this.flag = 0;
        this.$nextTick(()=>{
          this.flags = true;
        })
      }else if(this.rowSelect[0] > 0){
        this.flags = false;
        this.flag = 1;
        this.$nextTick(()=>{
          this.flags = true;
        })
      }else if(this.rowSelect[0] === 0){
        this.flags = false;
        this.flag = 2;
        this.$nextTick(()=>{
          this.flags = true;
        })
      }
      this.dialogTableVisible = true
      // this.multiSelection = [] 
      setTimeout(() => {
        this.$nextTick(()=>{
          if(this.isShowCol === true) {
            this.rowSelect.forEach(item1 =>{
              this.filterData.forEach(item =>{
                let dataparams = []
                if(item1 === item.user_id){
                  dataparams.push(item) 
                  this.toggleSelection(dataparams)
                }
              })
            })
          }
        })
      }, 100);
      this.$emit('openSelect',this.dialogTableVisible)
    },
    // 单选一个数值
    rowDblclick(row){
      if(this.isShowCol === false) {
        this.newRow = row
        this.$emit('getSelTableRow',this.newRow)
        this.dialogTableVisible = false
      }
    },
    // 多选数值
    handleSelectionChange(val){
      if(val.length>0){
        if(val[0].user_id>0){
          this.flags = false;
          this.flag = 1;
          this.$nextTick(()=>{
          this.flags = true;
        })
          setTimeout(()=>{
            let temp = [];
            val.forEach(item=>{
              this.$refs.multipleTable1.toggleRowSelection(item)
              temp.push(item.user_id)
            })
            this.multiSelection = val;
          },50)
        }else if(val[0].user_id === 0){
          this.flags = false;
          this.flag = 2;
          this.$nextTick(()=>{
          this.flags = true;
        })
          setTimeout(()=>{
            this.$refs.multipleTable2.toggleRowSelection(val[0])
            this.multiSelection = val;
            // console.log('this.$refs.multipleTable1',this.$refs.multipleTable2);
          },50)
        }
      }
      
    },
    handleSelectionChange1(val){
      this.multiSelection = val;
      let temp = [];
      val.forEach((item,index)=>{
        if(item.user_id > 0){
          temp.push(item.user_id)
        }
      })
      this.whether = true
      this.$emit("setRowSelect",temp)
      if(this.multiSelection.length === 0){
        this.flags = false;
        this.flag = 0;
        this.$nextTick(()=>{
          this.flags = true;
        })
      }
    },
    handleSelectionChange2(val){
      this.multiSelection = val
      let temp = [];
      val.forEach(item=>{
        temp.push(item.user_id)
      })
      this.$emit("setRowSelect",temp)
      if(this.multiSelection.length === 0){
        this.flags = false;
        this.flag = 0;
        this.$nextTick(()=>{
          this.flags = true;
        })
      }
    },
    // handleSelection(row){
    //   console.log('rrrrr',row)
    //   if(row[0].user_id !== 0){
    //     this.flag = false;
    //       // for(let j=0;j<this.filterData.length;j++){
            
    //       //   this.selecExternal(this.filterData[j],j,1)
    //       //   console.log('this.tableHeader[j]',this.filterData[this.filterData.length-1])
    //       // }

    //     this.$nextTick(()=>{
    //       this.flag = true;
    //     })
    //   }else{
    //     for(let j=0;j<this.filterData.length-1;j++){
    //       this.selecExternal(this.filterData[j],j,1)
    //     }
    //   }
    // },
    // 更新数据
    refreshTableData(){
      this.searchContent = ''
      this.$emit("refreshTableData")
    },
    selecExternal(row,index){
      return row.user_id > 0;
    },
    selecExternal1(row,index){
      return row.user_id === 0;
    },
    cancelSelection(){
      if(this.flag === 0){
        this.$refs.multipleTable.clearSelection();
      }else if(this.flag === 1){
        this.$refs.multipleTable1.clearSelection();
        this.flags = false;
        this.flag = 0;
        this.$nextTick(()=>{
          this.flags = true;
        })
      }else if(this.flag === 2){
        this.flags = false
        this.$refs.multipleTable2.clearSelection();
        this.flag = 0;
        this.$nextTick(()=>{
          this.flags = true;
        })
      }
    },
    // onChange(selectedRowKeys, selectedRows) {
    //   //选中的数据的key
    //   this.selectedRowKeys = selectedRowKeys
    //   if (selectedRows.length > 1) {
    //     //获取选中的数据的key
    //     const selectNumber = this.selectedRowKeys[1]
    //     //清空选中的key
    //     this.selectedRowKeys = []
    //     //选中的数据的key重新赋值给selectedRowKeys
    //     this.selectedRowKeys.push(selectNumber)
    //   }
    //   //获取选中的数据详细信息
    //   this.selectedRows = selectedRows[0]
    //
    // },
    sureSelection(){
      if(this.isShowCol === true){
        
        if(this.whether){
          for(let i=0;i<this.multiSelection.length;i++){
            if(this.multiSelection[i].user_id === 0){
              this.multiSelection.splice(i,1)
            }
          }
          this.whether = false;
        }
        this.$emit('getSelTableList',this.multiSelection)
        this.dialogTableVisible = false
      }
    },
    closeDialog(){
      if(this.flag === 0){
        this.$refs.multipleTable.clearSelection();
      }else if(this.flag === 1){
        this.flags = false;
        this.$refs.multipleTable1.clearSelection();
        
        this.flag = 0;
        this.$nextTick(()=>{
          this.flags = true;
        })
      }else if(this.flag === 2){
        this.flags = false
        this.$refs.multipleTable2.clearSelection();
        this.flag = 0;
        this.$nextTick(()=>{
          this.flags = true;
        })
      }
    }
  },
}
</script>

<style scoped lang="scss">
 .vd_tips{
   font-size: 12px;
   color: $color-info;
 }
 .table_header  .el-checkbox__inner:nth-of-type(1){ 
    display:none !important
}
::v-deep .el-dialog__body {
  margin: 20px;
}
::v-deep .el-input--medium .el-input__inner{
  border-color: #c1c1c1 !important;
}
</style>